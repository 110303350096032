import { useContext, useState, useCallback, memo } from 'react';

import { InfluencerModel } from 'api-models';
import MessageContent from 'components/Discovery/Components/MessageContent/MessageContent';
import useIntegratedInboxData from 'components/IntegratedInbox/hooks';
import ConfirmModal from 'components/Modals/ConfirmModal';
import DiscoveryContext from 'contexts/Discovery';
import IntegratedInboxService from 'services/IntegratedInbox/IntegratedInbox.service';
import { ICollabsData } from 'services/Response.types';
import toast from 'services/Toast';

type MessageModalProps = {
	open: boolean;
	onClose: () => void;
	influencer?: InfluencerModel | undefined;
	isList?: boolean;
	isOnCampaign?: boolean;
};

const MessageModal = memo(({ open, onClose, influencer, isList, isOnCampaign }: MessageModalProps) => {
	const { messageTargetInfluencer } = useContext(DiscoveryContext);
	const { getAllConversations } = useIntegratedInboxData();
	const { mutation: conversationsMutation } = getAllConversations();
	const [message, setMessage] = useState('');
	const [isSending, setIsSending] = useState(false);

	/**
	 * POST /influencers/{id}/conversation-messages requires influencer id (not collabsId or userId)
	 * but messageTargetInfluencer's id is collabsId because discovery view uses discovery api.
	 * So when the user sends the message, it will send the request to search the influencer id with collabsId first
	 * and then call createNewConversation method to send the message
	 */
	const sendIntegratedInboxMessage = useCallback(
		async (message: string): Promise<boolean> => {
			setIsSending(true);

			if (isOnCampaign && influencer) {
				await IntegratedInboxService.createDirectMessage({ message: message, influencerId: influencer.id! });
				toast.success(`Message has been sent`);
				setIsSending(false);
				conversationsMutation.refresh();
				onClose();
				return true;
			}

			if (isList) {
				if (messageTargetInfluencer) {
					const influencerData = await IntegratedInboxService.getInfluencer(messageTargetInfluencer.id);
					return await IntegratedInboxService.createDirectMessage({ message: message, influencerId: influencerData.id! }).then(() => {
						toast.success(`Message has been sent`);
						setIsSending(false);

						conversationsMutation.refresh();
						onClose();

						return true;
					});
				}
			} else {
				if (messageTargetInfluencer) {
					return IntegratedInboxService.getInfluencer(messageTargetInfluencer.id).then(async (influencerData: ICollabsData) => {
						return await IntegratedInboxService.createDirectMessage({ message: message, influencerId: influencerData.id! }).then(() => {
							toast.success(`Message has been sent`);
							setIsSending(false);

							conversationsMutation.refresh();
							onClose();

							return true;
						});
					});
				}
			}

			setIsSending(false);
			return new Promise((resolve) => resolve(false));
		},
		[influencer, messageTargetInfluencer, messageTargetInfluencer, conversationsMutation, onClose, isList, isOnCampaign],
	);

	return (
		<ConfirmModal
			isModalOpen={open}
			toggleModal={onClose}
			title='Private message'
			buttonText='Send'
			action={() => sendIntegratedInboxMessage(message)}
			isFetching={isSending}
		>
			<MessageContent
				onSend={sendIntegratedInboxMessage}
				onCloseModal={onClose}
				messageTargetInfluencer={messageTargetInfluencer ?? undefined}
				influencer={influencer}
				isList={isList || isOnCampaign}
				getMessage={(message) => setMessage(message)}
			/>
		</ConfirmModal>
	);
});

export default MessageModal;
