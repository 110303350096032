import { Network } from 'constants/socialMedia';

import Styled from './InfluencerAvatar.style';

type InfluencerProps = {
	displayNetwork: boolean;
	pulse?: boolean;
	inHeader?: boolean;
	profileImageUrl: string;
	userName: string;
	network?: Network;
	size?: 'sm' | 'md' | 'lg';
	scale?: 'sm' | 'md' | 'lg';
	hasAnAccount?: boolean;
	missingEmail?: boolean;
};
/**
 * Influencer avatar with network icon
 * @returns {JSX.Element}
 */
const InfluencerAvatar = ({
	userName,
	network,
	displayNetwork,
	profileImageUrl,
	inHeader,
	pulse,
	scale = 'md',
	hasAnAccount,
	missingEmail,
}: InfluencerProps): JSX.Element => {
	const getNetworkIcon = (network: Network) => {
		switch (network) {
			case Network.INSTAGRAM:
				return 'instagram-circle-color';
			case Network.TIKTOK:
				return 'tiktok-color';
			default:
				'';
		}
	};

	return (
		<Styled.Wrapper data-testid='avatar'>
			{profileImageUrl === '' ? (
				<Styled.LetterAvatar name={userName} scale={scale} />
			) : (
				<Styled.UserAvatar img={profileImageUrl} scale={scale} className={hasAnAccount ? 'account' : ''} />
			)}
			{inHeader && pulse && <Styled.PulseRing />}
			{displayNetwork && network && <Styled.NetworkIcon className={missingEmail ? 'missing-email' : ''} name={getNetworkIcon(network)} size='20' />}
			{missingEmail && <Styled.EmailIcon name='email-missing' size='13' />}
		</Styled.Wrapper>
	);
};

export default InfluencerAvatar;
