import styled from 'styled-components';

import newColors from 'styles/theme/colors';
import { scrollbarY } from 'styles/utils/scrollbar';
import borderRadius from 'styles/variables/border-radius';
import boxShadow from 'styles/variables/box-shadows';
import colors from 'styles/variables/colors';
import { guttersWithRem } from 'styles/variables/gutter';
import typography from 'styles/variables/typography';

const Wrapper = styled.div<{ height?: number }>`
	position: absolute;
	z-index: 30;
	width: 100%;
	max-height: ${(props) => `${props.height}px`};
	${scrollbarY}
	border-radius: ${borderRadius.m};
	background-color: ${colors.white};
	box-shadow: ${boxShadow.small};
	margin-top: 4px;
`;

const AutoSearchWrap = styled.ul`
	list-style-type: none;
	padding: ${guttersWithRem.xs} 0;
	margin: 0;
`;

const Select = styled.div`
	display: none;
	color: ${newColors.oceanBlue};
	font-size: 0.875rem;
	&.display {
		display: flex;
	}
`;

const AutoSearchData = styled.li`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1rem;
	font-family: ${typography.BaseFontFamiliy};
	transition: all 0.2s ease-in-out;
	position: relative;

	&:hover,
	&.selected {
		background-color: ${colors.discovery.autoCompleteHover};
		cursor: pointer;
		${Select} {
			display: block;
		}
	}

	span {
		&.bold {
			font-weight: ${({ theme }) => theme.fontWeights.semi};
		}
	}
	div {
		gap: 8px;
	}
`;

const CategoryBreadCrumbs = styled.span`
	margin-left: ${guttersWithRem.xxs};
	color: #888;
`;

const IconContainer = styled.div`
	border-radius: ${borderRadius.m};
	background-color: ${colors.buttonGray};
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
	.icon {
		margin-top: 3px;
	}
`;

const Label = styled.label`
	font-weight: 600;
	font-size: 0.875rem;
	color: ${newColors.slate};
	line-height: 2;
	margin-left: 12px;
`;

const Loading = styled.div`
	padding: 1rem;
	display: flex;
	align-items: center;
	gap: 8px;
	color: ${newColors.slate};
	font-size: 0.875rem;
`;

const Styled = {
	Wrapper,
	AutoSearchWrap,
	AutoSearchData,
	CategoryBreadCrumbs,
	IconContainer,
	Label,
	Loading,
	Select,
};

export default Styled;
