import moment from 'moment';

import InfluencerAvatar from 'components/InfluencerAvatar';
import { ApiStatus, Network } from 'constants/socialMedia';
import { ROLE_ADMINISTRATOR, ROLE_BRAND_MANAGER, ROLE_DATA_ANALYST, ROLE_PROJECT_MANAGER } from 'hooks/usePermissions/types';
import { PinebucketStatus } from 'reducers/UserReducers/types';
import { BrandPrivilege, ClientPrivilege, ClientUser, InfluencerUser, PublisherPrivilege, UserInfluencerProfile } from 'shared/Types/User';

import Styled from './Components/Table.style';

/**
 */

export const getPinebucketStatus = (status: string) => {
	switch (status) {
		case PinebucketStatus.NOT_CONNECTED:
			return <Styled.Status>Not connected</Styled.Status>;
		case PinebucketStatus.ACCOUNT_CREATED:
			return <Styled.Status>Account created</Styled.Status>;
		case PinebucketStatus.FULLY_REGISTERED:
			return <Styled.Status className='connected'>Fully registered</Styled.Status>;
		default:
			return '';
	}
};

export const getRole = (role: string) => {
	switch (role) {
		case ROLE_ADMINISTRATOR:
			return 'Manager';
		case ROLE_BRAND_MANAGER:
			return 'Viewer';
		case ROLE_DATA_ANALYST:
			return 'Data analyst';
		case ROLE_PROJECT_MANAGER:
			return 'Editor';
		default:
			return '';
	}
};

export const getNetworkStatus = (item: InfluencerUser) => {
	return (
		<Styled.List>
			{item.influencers.map((profile: UserInfluencerProfile, i: number) => {
				if (profile.apiStatus === ApiStatus.CONNECTED) {
					return (
						<Styled.InfluencerListItem key={i}>
							<InfluencerAvatar
								userName={profile.username}
								network={profile.network as Network}
								profileImageUrl={profile.links.profilePictureUrl ?? ''}
								displayNetwork
							/>
							<Styled.InfluencerAvatarWrapper>
								<h5>{profile.username}</h5>
								<Styled.Status className='connected'>Connected</Styled.Status>
							</Styled.InfluencerAvatarWrapper>
						</Styled.InfluencerListItem>
					);
				} else {
					return (
						<Styled.InfluencerListItem key={i}>
							<InfluencerAvatar
								userName={profile.username}
								network={profile.network as Network}
								profileImageUrl={profile.links.profilePictureUrl ?? ''}
								displayNetwork
							/>
							<Styled.InfluencerAvatarWrapper>
								<h5>{profile.username}</h5>
								<Styled.Status>Not connected</Styled.Status>
							</Styled.InfluencerAvatarWrapper>
						</Styled.InfluencerListItem>
					);
				}
			})}
		</Styled.List>
	);
};

export const getPublisherAndRole = (item: ClientUser) => {
	const isPublisherUser = item.publisherPrivileges?.length > 0;
	return (
		isPublisherUser && (
			<>
				<Styled.RoleTitle>Publisher</Styled.RoleTitle>
				{item.publisherPrivileges?.map((publisher: PublisherPrivilege, i: number) => (
					<Styled.Role key={i}>
						<div>{publisher.publisher.name}</div> <div>{getRole(publisher.role)}</div>
					</Styled.Role>
				))}
			</>
		)
	);
};

export const getBrandAndRole = (item: ClientUser) => {
	const isBrandUser = item.brandPrivileges?.length > 0;
	return (
		isBrandUser && (
			<>
				<Styled.RoleTitle>Brand</Styled.RoleTitle>
				{item.brandPrivileges?.map((brand: BrandPrivilege, i: number) => (
					<Styled.Role key={i}>
						<div>{brand.brand.name}</div> <div>{getRole(brand.role)}</div>
					</Styled.Role>
				))}
			</>
		)
	);
};

export const getClientAndRole = (item: ClientUser) => {
	const isClientUser = item.clientPrivileges?.length > 0;
	return (
		isClientUser && (
			<>
				<Styled.RoleTitle>Client</Styled.RoleTitle>
				{item.clientPrivileges?.map((client: ClientPrivilege, i: number) => (
					<Styled.Role key={i}>
						<div>{client.client.name}</div> <div>{getRole(client.role)}</div>
					</Styled.Role>
				))}
			</>
		)
	);
};

const getRoles = (item: ClientUser) => {
	return (
		<>
			{getPublisherAndRole(item)}
			{getBrandAndRole(item)}
			{getClientAndRole(item)}
		</>
	);
};

export const getBrand = (item: ClientUser) => {
	const isPublisherUser = item.publisherPrivileges?.length > 0;
	const isClientUser = item.clientPrivileges?.length > 0;
	const isBrandUser = item.brandPrivileges?.length > 0;

	return (
		<>
			{isPublisherUser
				? item.publisherPrivileges?.map((publisher: PublisherPrivilege, i: number) => <div key={i}>{publisher.publisher.name}</div>)
				: isClientUser
					? item.clientPrivileges?.map((client: ClientPrivilege, i: number) => <div key={i}>{client.client.name}</div>)
					: isBrandUser && item.brandPrivileges?.map((brand: BrandPrivilege, i: number) => <div key={i}>{brand.brand.name}</div>)}
		</>
	);
};

export const renderInfo = (item: ClientUser | InfluencerUser) => {
	const userIsInfluencer = item?.influencers?.length > 0;
	const isClientUser = (item as ClientUser)?.clientPrivileges?.length > 0;

	const getClientBilingInfo = () => {
		return [
			{ title: 'Company name', value: (item as ClientUser).clientPrivileges[0]?.client.legalName, copy: true },
			{ title: 'Organisation number', value: (item as ClientUser).clientPrivileges[0]?.client.organizationNumber, copy: true },
			{ title: 'Billing email', value: (item as ClientUser).clientPrivileges[0]?.client.invoiceEmail, copy: true },
			{ title: 'Company address', value: (item as ClientUser).clientPrivileges[0]?.client.invoiceAddress, copy: true },
			{ title: 'Zip Code', value: (item as ClientUser).clientPrivileges[0]?.client.invoicePostalCode, copy: true },
			{ title: 'City', value: (item as ClientUser).clientPrivileges[0]?.client.invoiceCity, copy: true },
			{ title: 'Country', value: (item as ClientUser).clientPrivileges[0]?.client.invoiceCountryCode, copy: true },
			{ title: 'VAT number', value: (item as ClientUser).clientPrivileges[0]?.client.vatNumber, copy: true },
		];
	};

	const InfluencerContactInfo = {
		title: 'Contact info',
		data: [
			{ title: 'Contact email', value: item.email, copy: true },
			{ title: 'Phone', value: item.mobilePhoneNumber, copy: true },
		],
	};

	const PublisherContactInfo = {
		title: 'Contact info',
		data: [
			{ title: 'Contact email', value: item.email, copy: true },
			{ title: 'Phone', value: item.mobilePhoneNumber, copy: true },
			{ title: 'Organization owner', value: '', copy: true },
		],
	};

	const DeliveryInfo = {
		title: 'Delivery Info',
		data: [
			{ title: 'Name', value: item.firstName + ' ' + item.lastName, copy: true },
			{ title: 'Address', value: item.address, copy: true },
			{ title: 'Zip code', value: item.postalCode, copy: true },
			{ title: 'City', value: item.city, copy: true },
			{ title: 'Country', value: item.countryCode, copy: true },
		],
	};

	const PaymentInfo = {
		title: 'Payment info',
		data: [
			{ title: 'Legal Country', value: item.legalCountryCode, copy: true },
			{ title: 'VAT number', value: item.vatNumber, copy: true, edit: { hateoas: 'changeVatNumber', fieldName: 'vatNumber' } },
			{ title: 'VAT number valid', value: item.vatNumber ? (item.vatNumberValid ? 'true' : 'false') : null, copy: true },
			{ title: 'VAT number validated at', value: item.vatNumberValidatedAt ? moment(item.vatNumberValidatedAt).format('YYYY-MM-DD HH:mm') : null, copy: true },
		],
	};

	const Roles = {
		title: 'Roles',
		data: [
			{ title: '', value: getRoles(item as ClientUser) },
			{ title: 'id', value: item.id },
		],
	};

	const Connections = {
		title: 'Connections',
		data: [
			{ title: 'Networks', value: getNetworkStatus(item) },
			{ title: 'Collabs Payout', value: getPinebucketStatus(item.pinebucketStatus) },
		],
	};

	const BillingInfo = {
		title: 'Billing info',
		data: isClientUser
			? getClientBilingInfo()
			: [
					{ title: 'Company name', value: (item as ClientUser).publisherPrivileges[0]?.publisher.legalName, copy: true },
					{ title: 'Organisation number', value: (item as ClientUser).publisherPrivileges[0]?.publisher.organizationNumber, copy: true },
					{ title: 'Billing email', value: (item as ClientUser).publisherPrivileges[0]?.publisher.invoiceEmail, copy: true },
					{ title: 'Company address', value: (item as ClientUser).publisherPrivileges[0]?.publisher.invoiceAddress, copy: true },
					{ title: 'Zip Code', value: (item as ClientUser).publisherPrivileges[0]?.publisher.invoicePostalCode, copy: true },
					{ title: 'City', value: (item as ClientUser).publisherPrivileges[0]?.publisher.invoiceCity, copy: true },
					{ title: 'Country', value: (item as ClientUser).publisherPrivileges[0]?.publisher.invoiceCountryCode, copy: true },
					{ title: 'VAT number', value: (item as ClientUser).publisherPrivileges[0]?.publisher.vatNumber, copy: true },
				],
	};

	return userIsInfluencer ? [InfluencerContactInfo, DeliveryInfo, Connections, PaymentInfo] : [Roles, PublisherContactInfo, BillingInfo];
};
