import styled, { DefaultThemeV2 } from 'styled-components';

import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';

import { IDropdownItem } from './types';

const IconWrapper = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: background-color ease-in-out 0.2s;
	width: 30px;
	height: 30px;
	border-radius: ${({ theme }: { theme: DefaultThemeV2 }) => theme.radius.small};
	.icon {
		margin-top: 3px;
	}
	&:hover {
		background-color: ${newColors.ash};
	}

	&.display-circle {
		border-radius: 50%;
		width: 40px;
		height: 40px;
	}
`;

const DropdownMenu = styled.div<IDropdownItem>`
	display: flex;
	flex-direction: column;
	position: absolute;
	transform: translate3d(0px, 28px, 0px);
	top: 0px;
	right: 0px;
	will-change: transform;
	z-index: 1000;
	display: none;
	float: left;
	min-width: ${(props) => props.minWidth || '260px'};
	width: -webkit-fill-available;
	width: auto;
	padding: 0.5rem;
	margin: 2px 0 0;
	font-size: 1rem;
	text-align: left;
	list-style: none;
	background-color: ${colors.dropdown.menu.background};
	background-clip: padding-box;
	border-radius: ${borderRadius.s};
	box-shadow: 0px 10px 20px 0px #5c688029;
`;

const DropdownItem = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0.5rem;
	text-align: inherit;
	white-space: nowrap;
	background-color: ${colors.dropdown.menuItem.background};
	border: 0;
	border-radius: 4px;
	height: 40px;
	transition: background-color 0.2s ease-in-out;

	&:not(.disabled):hover {
		background-color: ${colors.dropdown.menuItem.backgroundHover};
		cursor: pointer;

		&.danger {
			background-color: ${colors.dropdown.menuItem.dangerBackgroundHover};
		}
	}

	a {
		display: block;
		width: 100%;
		font-size: 0.875rem;
		color: ${colors.dropdown.menuItem.color};
		padding: 0.1875rem 0.125rem 0 0.125rem;
	}

	button {
		font-size: 1rem;
		background-color: transparent;

		&:hover:not(:disabled, .disabled) {
			border-color: transparent;
		}
	}

	&.noClick {
		cursor: auto !important;

		&:hover {
			background-color: ${colors.dropdown.menuItem.background};
		}
	}
	&.dark {
		&:hover {
			background-color: ${newColors.ash};
		}
	}
	&.disabled {
		opacity: 0.7;
	}
`;

const Wrapper = styled.div`
	position: relative;
	width: fit-content;
`;

const DropdownWrapper = styled.div`
	position: relative;
	display: inline-flex;
	&.show {
		${DropdownMenu} {
			display: block;
		}
	}

	&.left {
		${DropdownMenu} {
			left: 0;
			right: auto;
		}
	}

	&.right {
		${DropdownMenu} {
			right: 0;
			left: auto;
		}
	}
`;

const tooltip = styled.div`
	position: absolute;
	background-color: ${colors.dropdown.tooltip.background};
	box-shadow: 0px 3px 6px ${colors.dropdown.tooltip.boxShadowColor};
	border-radius: 0.125rem;
	padding: 0.25rem;
	opacity: 0;
	transition: opacity 0.2s linear;
	margin-left: auto;
	margin-right: auto;
	margin-top: 4px;
	white-space: nowrap;
	top: 100%;
	right: 0;

	&.visible {
		opacity: 1;
	}
`;

const tooltipText = styled.span`
	color: ${colors.dropdown.tooltip.color};
	font-size: 0.75rem;
`;

const Button = styled.button<{ disabled?: boolean }>`
	display: flex;
	align-items: center;
	padding: 0.5rem;
	background-color: transparent;
	border: none;
	border-radius: 0.125rem;
	cursor: ${(p) => (!p.disabled ? 'pointer' : 'unset')};
	white-space: nowrap;
	&:hover,
	&.show {
		border-radius: 50% !important;
	}

	& > i {
		line-height: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		width: auto;
		height: auto;
	}

	span + i {
		margin-left: 4px;
	}
`;

const Styled = {
	Wrapper,
	Button,
	DropdownWrapper,
	DropdownMenu,
	DropdownItem,
	tooltip,
	tooltipText,
	IconWrapper,
};

export default Styled;
