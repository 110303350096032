import { isNil } from 'lodash';
import { memo, useState } from 'react';

import { InfluencerModel, UserModel } from 'api-models';
import Dropdown from 'components/Dropdown';
import { DropdownItem, DropdownMenu } from 'components/Dropdown/Dropdown';
import Icon from 'components/Icon';
import InfluencerAvatar from 'components/InfluencerAvatar';
import LoadingSpinner from 'components/LoadingSpinner';
import Pill from 'components/Pill';
import Tooltip from 'components/Tooltip/V2';
import { CREATE_EMAIL_REQUEST } from 'constants/hateoas-keys';
import { Network } from 'constants/socialMedia';
import useFeaturePermissions from 'hooks/FeaturePermissions';
import { getErrorMessageOnPost } from 'hooks/ToastPortal/toastMessages';
import useInjection from 'hooks/useInjection';
import { UserPaymentType } from 'reducers/UserReducers/types';
import InfluencerManager from 'services/ApiManager/Influencer.manager';
import toast from 'services/Toast';
import { formatNumber } from 'shared/helpers/Chart/chart-util';

import Styled from './InfluencerAvatarWithFollowers.style';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const InfluencerAvatarWithFollowers = memo(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(props: {
		username: string;
		network: Network;
		networkLink: string;
		profileImageUrl: string;
		followersCount: number;
		user?: UserModel;
		influencer?: InfluencerModel;
		inDiscovery?: boolean;
		hideEmail?: boolean;
		dropdownPosition?: 'right' | 'left';
	}) => {
		const {
			network,
			networkLink,
			username,
			profileImageUrl,
			followersCount,
			user,
			influencer,
			inDiscovery,
			dropdownPosition = 'left',
			hideEmail = false,
		} = props;
		const fp = useFeaturePermissions(influencer?.links);
		const canCreateEmailRequest = fp.userCan(CREATE_EMAIL_REQUEST);

		const notACollabsUser = isNil(user);
		const profileHandledByAgent = user?.attributes.kind === UserPaymentType.AGENT || user?.attributes.kind === UserPaymentType.LEGACY_AGENT;
		const missingEmail = influencer?.links?.createEmailRequest !== undefined;
		const tooltipContent = !notACollabsUser ? 'Verified Collabs user' : '';

		const manager = useInjection<InfluencerManager>(InfluencerManager);
		const [isEmailRequestLoading, setIsEmailRequestLoading] = useState(false);

		const renderPayoutType = () => {
			switch (user?.attributes.kind) {
				case UserPaymentType.INDIVIDUAL:
					return (
						<>
							<Icon name='manual-payments' size='16' />
							Manual payments
						</>
					);
				case UserPaymentType.AGENT:
				case UserPaymentType.COMPANY:
					return (
						<>
							<Icon name='automatic-payments' size='16' />
							Automatic payments
						</>
					);
				default:
					return !notACollabsUser ? (
						<>
							<Icon name='select-option' size='16' />
							No payment option
						</>
					) : null;
			}
		};

		const onClickCreateEmailRequest = async (id?: string) => {
			if (!canCreateEmailRequest || !id) {
				return;
			}
			setIsEmailRequestLoading(true);
			try {
				await manager.createEmailRequest(id);
				toast.success('Email requested successfully!');
			} catch (error) {
				toast.error(getErrorMessageOnPost('sending a email request'));
			} finally {
				setIsEmailRequestLoading(false);
			}
		};

		return (
			<Styled.Container>
				<Tooltip className={notACollabsUser && !missingEmail ? 'hideCursor' : ''} delayShow={200} content={inDiscovery ? '' : tooltipContent}>
					<InfluencerAvatar
						scale='lg'
						userName={username}
						network={network as Network}
						displayNetwork
						profileImageUrl={profileImageUrl}
						hasAnAccount={!notACollabsUser}
						missingEmail={!hideEmail && !influencer?.attributes.email}
					/>
				</Tooltip>
				<Styled.InfluencerNameWrapper>
					<Styled.InfluencerName>
						<span>{username}</span>
						<Dropdown icon='chevron-down' position={dropdownPosition}>
							<DropdownMenu>
								{!inDiscovery && (
									<>
										<DropdownItem className='noClick'>
											<Styled.EmailWrapper>
												<Icon name={notACollabsUser ? 'user' : 'verified-user'} size='16' />
												{notACollabsUser ? 'No Collabs account' : 'Verified Collabs user'}
											</Styled.EmailWrapper>
										</DropdownItem>
										{profileHandledByAgent && (
											<DropdownItem className='noClick'>
												<Styled.EmailWrapper>
													<Icon name='mail' size='16' />
													{influencer?.attributes.email} <Pill className='agent' title='Agent' />
												</Styled.EmailWrapper>
											</DropdownItem>
										)}
										{!hideEmail && notACollabsUser && (
											<DropdownItem className={!missingEmail ? 'noClick' : ''} onClick={() => onClickCreateEmailRequest(influencer?.id)}>
												<Styled.EmailWrapper>
													<Icon name={influencer?.attributes.email ? 'mail' : 'email-missing'} size='16' />
													{influencer?.attributes.email ?? 'Missing email'}
													{missingEmail && (
														<Styled.RequestButton>
															{isEmailRequestLoading ? (
																<LoadingSpinner size='sm' />
															) : (
																<Tooltip position='bottom' content='Send a request to the Email Finder Supervisor'>
																	<Icon className='request' name='refresh' size='16' />
																</Tooltip>
															)}
														</Styled.RequestButton>
													)}
												</Styled.EmailWrapper>
											</DropdownItem>
										)}
										{!notACollabsUser && (
											<DropdownItem className='noClick'>
												<Styled.EmailWrapper>{renderPayoutType()}</Styled.EmailWrapper>
											</DropdownItem>
										)}
									</>
								)}
								<Styled.GrayWrapper>
									<DropdownItem className='dark'>
										<Styled.LinkToNetwork href={networkLink} target='_blank' onClick={(e) => e.stopPropagation()}>
											<span>{`Visit profile on ${network === Network.INSTAGRAM ? 'Instagram' : 'Tiktok'}`}</span>
											<Icon name='external-link' size='14' />
										</Styled.LinkToNetwork>
									</DropdownItem>
								</Styled.GrayWrapper>
							</DropdownMenu>
						</Dropdown>
					</Styled.InfluencerName>
					<Styled.InfluencerSubText className='follower'>
						<div>{followersCount && formatNumber(followersCount)} Followers</div>
					</Styled.InfluencerSubText>
				</Styled.InfluencerNameWrapper>
			</Styled.Container>
		);
	},
);

export default InfluencerAvatarWithFollowers;
