import { CSSProperties } from 'react';
import styled, { DefaultThemeV2 } from 'styled-components';

import Dropdown from 'components/Dropdown';
import Icon from 'components/Icon';
import newColors, { colors } from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import { guttersWithRem } from 'styles/variables/gutter';
import { focusInput } from 'styles/variables/keyframes';
import { spacing } from 'styles/variables/spacings';
import typography from 'styles/variables/typography';

type T = DefaultThemeV2;

const MIDDLE_SECTION_HEIGHT = '303px';

const FlexDiv = styled.div<CSSProperties>`
	display: flex;
	height: 24px;
	position: ${(props) => props.position};
	column-gap: ${(props) => props.columnGap};
	align-items: ${(props) => props.alignItems};
	top: ${(props) => props.top};
	left: ${(props) => props.left};
	right: ${(props) => props.right};
	bottom: ${(props) => props.bottom};
	font-weight: ${({ theme }: { theme: T }) => theme.fontWeights.medium};
`;

const CustomIcon = styled(Icon)`
	line-height: 0;
	cursor: pointer;
	display: flex;
`;

const ActionIconWrapper = styled.div`
	width: 24px;
	height: 24px;
	border-radius: ${({ theme }: { theme: T }) => theme.radius.small};
	display: flex;
	justify-content: center;
	border: none;
	&:hover {
		background-color: ${newColors.snow};
	}
`;

const CustomDropdown = styled(Dropdown)`
	display: flex;
`;

const MenuHeader = styled.div`
	padding: ${spacing[1].rem};
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: ${typography.BaseFontFamiliy};
	border-bottom: 1px solid ${newColors.fog};
	font-weight: ${({ theme }: { theme: T }) => theme.fontWeights.medium};
	margin-bottom: 8px;
	height: 36px;
`;

const CreateNewWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: ${spacing[2].px};
	margin-bottom: ${spacing[1].px};

	& > button {
		flex-grow: 1;
		flex-basis: 0;
		height: 36px;
	}
`;

const MenuItemsWrapper = styled.div`
	max-height: ${MIDDLE_SECTION_HEIGHT};
	overflow-y: auto;

	&.campaign-list {
		max-height: 347px;
	}
`;

const MenuFooter = styled(CreateNewWrapper)`
	border-top: 1px solid ${newColors.fog};
	padding-top: ${spacing[1].rem};
	margin-bottom: unset;

	& > button {
		padding: 1rem;
		flex-grow: unset;
		flex-basis: unset;
		width: fit-content;
		white-space: nowrap;
		padding: 0 1rem;
	}
`;

const Name = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: fit-content;
`;

const NameWrapper = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

const DropdownItemInnerWrapper = styled(Dropdown.Item)`
	width: 100%;
	justify-content: space-between;
	text-align: center;
	user-select: none;
	margin-top: ${spacing[1].rem};
	height: 60px;
	& > div {
		display: flex;
		align-items: center;
		column-gap: ${guttersWithRem.xs};

		&.icon-wrapper {
			display: flex;
		}
	}

	&.list {
		justify-content: flex-start;
		align-items: center;
		column-gap: ${guttersWithRem.xs};
	}

	&.create-new-list {
		font-family: ${typography.BaseFontFamiliy};
	}
	&.displaySegments {
		background-color: ${newColors.mist};
		height: auto;
		cursor: auto;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}
	&.selected-item {
		background-color: ${newColors.ash};
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 0.5rem 1rem;
	}
	&.selected-list-item {
		background-color: ${newColors.ash};
	}
	&.cursor {
		background-color: ${newColors.ash};
	}
	&.create-list {
		animation: 0.3s ${focusInput} ease-in-out;
		position: absolute;
		left: 0;
		top: 45px;
		background-color: ${newColors.snow};
	}
`;

const Input = styled.input`
	width: 100%;
	&:focus,
	&:active {
		outline: none;
	}
	background-color: ${newColors.mist};
	border-radius: ${({ theme }: { theme: T }) => theme.radius.small};
	border: 1px solid ${newColors.smoke};
	overflow: hidden;
`;

const CustomMenu = styled(Dropdown.Menu)`
	max-height: 452px;
	width: 550px;
	display: flex !important;
	border-radius: ${({ theme }: { theme: T }) => theme.radius.default};
	cursor: default;
`;

const EmptyState = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: ${MIDDLE_SECTION_HEIGHT};
	flex-grow: 1;

	image {
		width: 70px;
		height: auto;
		margin-bottom: 16px;
	}
	p {
		font-weight: ${({ theme }: { theme: T }) => theme.fontWeights.medium};
		text-align: center;
		width: 70%;
		margin: 0 auto;
		margin-bottom: 24px;
		margin-top: 24px;
		font-size: 1rem;
		text-wrap: balance;
	}
	button {
		font-size: 1rem;
		padding: 1rem !important;
	}
`;

const SegmentsWrapper = styled.div`
	padding: 1rem 0;
	width: 100%;
	ul {
		width: 100%;
	}
`;

const SelectText = styled.div`
	display: none;
	color: ${colors.oceanBlue};
	font-size: 0.875rem;
`;

const SegmentItem = styled.li`
	cursor: pointer !important;
	padding: 1rem;
	border-radius: ${borderRadius.s};
	width: 100%;
	text-align: start;
	border: 1px solid transparent;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	&:hover {
		border: 1px solid ${colors.skyBlue};
		${SelectText} {
			display: flex;
		}
	}
	&.slected {
		background-color: ${colors.ash};
		${SelectText} {
			display: flex;
		}
	}
`;

const SegmentTitle = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const Styled = {
	EmptyState,
	CustomMenu,
	FlexDiv,
	CustomIcon,
	CustomDropdown,
	MenuHeader,
	MenuItemsWrapper,
	MenuFooter,
	DropdownItemInnerWrapper,
	Input,
	CreateNewWrapper,
	ActionIconWrapper,
	Name,
	NameWrapper,
	SegmentsWrapper,
	SegmentItem,
	SelectText,
	SegmentTitle,
};

export default Styled;
