import { AxiosError } from 'axios';
import { Store } from 'json-api-models';

import useInjection from 'hooks/useInjection';
import { setPermissions } from 'reducers/UserReducers/UserPermissionsSlice';
import { setUser } from 'reducers/UserReducers/UserSlice';
import CollabsAuthService from 'services/Authentication/Collabs-api';
import LogoutService from 'services/Authentication/Collabs-api/LogoutService';
import { StatusCode } from 'services/Response.types';
import { store } from 'store/User';

const logoutService = useInjection<LogoutService>(LogoutService);

export const updateUser = async (callback?: () => void) => {
	const models = new Store();

	await CollabsAuthService.me(CollabsAuthService.getGlobalUserIncludes())
		.then((result) => {
			try {
				models.sync(result);
				const user = models.findAll('user')[0];
				// dispatch setUser in redux
				store.dispatch(setUser(user));
				store.dispatch(setPermissions(user));

				callback && setTimeout(() => callback());
			} catch (error) {
				console.error(error);
			}
		})
		.catch((error: AxiosError) => {
			if (error.request?.status === StatusCode.UNAUTHORIZED) {
				logoutService.logout(store.dispatch);
			}
		});
};

export const silentRefreshUser = async (callback?: () => void) => {
	const models = new Store();

	await CollabsAuthService.me(CollabsAuthService.getGlobalUserIncludes()).then((result) => {
		try {
			models.sync(result);
			const user = models.findAll('user')[0];
			// dispatch setUser in redux
			store.dispatch(setUser(user));
			store.dispatch(setPermissions(user));

			callback && setTimeout(() => callback());
		} catch (error) {
			console.error(error);
		}
	});
};

export const getActivePublisher = (publisherId?: string) => {
	const user = store.getState().user;
	const permissions = user.permissions;

	if (!publisherId) {
		const publishers = permissions.entities && Object.values(permissions.entities).filter((e) => e.kind === 'publisher');
		return publishers !== null && publishers.length > 0 && publishers[0];
	}

	return permissions.entities !== null ? permissions.entities[publisherId] : null;
};

export const getInitials = (name: string) => {
	if (!name) {
		return '';
	}

	if (name.length === 2) {
		return name;
	} else if (name?.indexOf(' ') >= 0) {
		return name
			.split(' ')
			.map((word: string) => word[0])
			.join('')
			.slice(0, 2);
	} else {
		return name.charAt(0);
	}
};
