import styled, { DefaultThemeV2 } from 'styled-components';

import colors from 'styles/theme/colors';

type T = { theme: DefaultThemeV2 };

const Pill = styled.div`
	font-size: 0.75rem;
	text-transform: capitalize;
	color: ${colors.smoke};
	background-color: ${colors.mist};
	border-radius: ${(props: T) => props.theme.radius.default};
	padding: 0.3rem 0.75rem;
	width: fit-content;
	div {
		text-align: center;
		margin-top: 2px;
	}
	&.client,
	&.company {
		background-color: ${colors.paleGreenTint};
		color: ${colors.smoke};
		margin-left: 5px;
	}
	&.brand,
	&.agent {
		background-color: ${colors.iceBlue} !important;
		color: ${colors.smoke};
		margin-left: 5px;
	}
	&.publisher {
		background-color: ${colors.ash};
	}
	&.archived {
		background-color: ${colors.butter};
	}
	&.status-count {
		font-weight: 600;
		background-color: ${colors.ash};
	}
	&.icons {
		font-weight: 500;
		background-color: ${colors.paleGreenTint};
		padding: 0.1rem 0.4rem;
	}
	&.discovery-filter {
		background-color: ${colors.ash};
		font-weight: 600;
	}
	&.total {
		background-color: ${colors.mist};
		font-weight: 600;
	}
`;

export default {
	Pill,
};
