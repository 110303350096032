import { uniq } from 'lodash';
import { useEffect, useState, useContext } from 'react';

import LoadingSpinner from 'components/LoadingSpinner';
import PageHeader from 'components/PageHeader';
import Tabs from 'components/Tabs/Tabs';
import { Network } from 'constants/socialMedia';
import DiscoveryContext from 'contexts/Discovery';
import { InfluencerListItemType } from 'contexts/Discovery/types';
import { setSearchText } from 'reducers/AffiliateMarketplaceReducers/AMInfluencerSlice';
import MainContent from 'styles/layout/main-content';

import { AutoCompleteOption } from './Components/AutoCompleteList/AutoCompleteList';
import FilterSection from './Components/FilterSection/FilterSection';
import MessageModal from './Components/MessageModal';
import ResultContainer from './Components/ResultContainer';
import SearchExamples from './Components/SearchExamples/SearchExamples';
import SearchSection from './Components/SearchSection/SearchSection';
import SelectedInfluencerOptions from './Components/SelectedInfluencerOptions/SelectedInfluencerOptions';
import Styled from './DiscoveryContainer.style';

const DiscoveryContainer = () => {
	const { isLoading, isLoadingNext, searchText, searchResult, filter, isMessageModalOpen, hasSearched } = useContext(DiscoveryContext);

	const changeSearchValueHandler = useContext(DiscoveryContext).changeSearchValueHandler;
	const setFilter = useContext(DiscoveryContext).setFilter;
	const updateNetworkHandler = useContext(DiscoveryContext).updateNetworkHandler;
	const closeMessageModal = useContext(DiscoveryContext).closeMessageModal;
	const setHasSearched = useContext(DiscoveryContext).setHasSearched;

	const [showFilter, setShowFilter] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const [didYouMeanOptions, setDidYouMeanOptions] = useState<AutoCompleteOption[]>([]);

	const [selectedInfluencers, setSelectedInfluencers] = useState<string[]>([]);
	const [selectedInfluencersResult, setSelectedInfluencersResult] = useState<InfluencerListItemType[]>([]);
	const [showSelectedInfluencer, setShowSelectedInfluencers] = useState<boolean>(false);

	useEffect(() => {
		setHasSearched(false);
		return () => {
			setHasSearched(false);
		};
	}, []);

	const selectAllHandler = () => {
		if (searchResult) {
			if (selectedInfluencers.length === searchResult.length) {
				setSelectedInfluencers([]);
			} else {
				setSelectedInfluencers(searchResult.map((influencer: InfluencerListItemType) => influencer.id));
			}
		}
	};

	const selectOneHandler = () => {
		return (id: string) => {
			setSelectedInfluencers((prev) => {
				const isExist = prev.some((prevId) => prevId === id);
				if (isExist) {
					return prev.filter((prevId) => prevId !== id);
				} else {
					return prev.concat(id);
				}
			});
		};
	};

	useEffect(() => {
		const influencersInSearchResult = () => {
			const total = uniq(searchResult?.filter((result) => selectedInfluencers?.includes(result.id))) || [];
			return total;
		};

		const influencersInSavedSearchResult = () => {
			const total = uniq(selectedInfluencersResult?.filter((result) => selectedInfluencers?.includes(result.id))) || [];
			return total;
		};

		const total = influencersInSearchResult().concat(influencersInSavedSearchResult());
		const searchResultEmpty = influencersInSearchResult().length === 0;
		const savedSearchResultEmpty = influencersInSavedSearchResult().length === 0;

		if (!searchResultEmpty && !savedSearchResultEmpty) {
			setSelectedInfluencersResult(uniq(total));
		} else if (searchResultEmpty) {
			setSelectedInfluencersResult(influencersInSavedSearchResult());
		} else if (savedSearchResultEmpty) {
			setSelectedInfluencersResult(influencersInSearchResult());
		}
	}, [selectedInfluencers]);

	useEffect(() => {
		setShowSelectedInfluencers(false);
	}, [searchResult]);

	const clearSearch = () => {
		changeSearchValueHandler({
			text: '',
		});
		setSearchText('');
		setIsSearching(false);
	};

	const handleSelectOption = (id: string, label: string, network: Network) => {
		changeSearchValueHandler({ text: `@${label}` });
		setFilter({ ...filter, networks: network });
		updateNetworkHandler(network);
	};

	const renderData = () => {
		return showSelectedInfluencer ? selectedInfluencersResult : searchResult ?? [];
	};

	return (
		<Styled.Wrapper>
			<Styled.Header>
				<PageHeader headline='Discovery'></PageHeader>
				<MainContent>
					<Styled.SearchArea>
						<Styled.TabsWrapper>
							<Tabs
								className='on-discovery'
								tabs={[
									{ title: Network.INSTAGRAM, icon: 'instagram' },
									{ title: Network.TIKTOK, icon: 'tiktok' },
								]}
								selectedTab={filter?.networks as Network}
								setSelectedTab={(tab) => updateNetworkHandler(tab as Network)}
							/>
						</Styled.TabsWrapper>
						<SearchSection
							setDidYouMeanOptions={setDidYouMeanOptions}
							setShowFilter={setShowFilter}
							showFilter={showFilter}
							isSearching={isSearching}
							handleSelectOption={handleSelectOption}
							setIsSearching={setIsSearching}
						/>
						<FilterSection showFilter={showFilter} setShowFilter={setShowFilter} />
					</Styled.SearchArea>
					<SelectedInfluencerOptions
						selectedInfluencers={selectedInfluencers}
						setSelectedInfluencers={setSelectedInfluencers}
						showSelectedInfluencer={showSelectedInfluencer}
						setShowSelectedInfluencers={setShowSelectedInfluencers}
					/>
				</MainContent>
			</Styled.Header>
			<MainContent>
				{isLoading ? (
					<LoadingSpinner position='center' /> // Replace with retriving influencers
				) : (
					<>
						{searchResult && searchText && (
							<Styled.ResultText>
								Showing results for <strong>{searchText}</strong>
							</Styled.ResultText>
						)}
						<Styled.ResultContentWrapper>
							{
								//Show SearchExamples only if no search has been done
								!hasSearched ? (
									<SearchExamples setShowFilter={setShowFilter} />
								) : (
									<ResultContainer
										setShowSelectedInfluencers={setShowSelectedInfluencers}
										isLoadingNext={isLoadingNext}
										isInfluencerSearch={searchText && searchText.startsWith('@') ? true : false}
										tableList={renderData()}
										selectedInfluencers={selectedInfluencers}
										onSelectAll={selectAllHandler}
										onSelectOne={selectOneHandler}
										handleSelectOption={handleSelectOption}
										clearSearch={clearSearch}
										fuzzySearchOptions={didYouMeanOptions}
									/>
								)
							}
						</Styled.ResultContentWrapper>
					</>
				)}
			</MainContent>
			<MessageModal open={isMessageModalOpen} onClose={closeMessageModal} />
		</Styled.Wrapper>
	);
};

export default DiscoveryContainer;
