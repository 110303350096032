import { Model } from 'json-api-models';

import { AssignmentType } from 'components/Assigment/types';
import { CampaignItemType } from 'components/ContentManagement/types';

import StatisticsForm from './StatisticsForm';

export type StatisticsProps = {
	active: boolean;
	selectedCampaign: CampaignItemType;
	selectedCIO?: Model;
	CIOAssignment: Model;
	assignmentType: AssignmentType;
	review: Model;
	assignment: Model;
	influencer: Model;
	refresh: () => Promise<void>;
	getUnsavedChanges: (unsavedChanges: boolean) => void;
};

const Statistics = (props: StatisticsProps) => {
	const renderForm = () => {
		return <StatisticsForm {...props} />;
	};

	return renderForm();
};

export default Statistics;
