import { Model } from 'json-api-models';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { InfluencerListFolderModel, InfluencerListListItemModel, InfluencerListListModel } from 'api-models';
import InfluencerListContainer from 'components/Lists/InfluencerListContainer';
import { DiscoveryContextProvider } from 'contexts/Discovery/DiscoveryContext';
import usePermissions from 'hooks/usePermissions';
import { pathInfluencerManagement } from 'routing/PathLookup';
import { MutateFn } from 'services/ApiManager/types';

import BreadCrumbs from './BreadCrumbs/BreadCrumbs';
import CreateFolder from './Folders/Create/CreateFolder';
import ShareFolder from './Folders/Share/ShareFolder';
import Styled from './InfluencerManagement.style';
import CreateList from './Lists/Create/CreateList';
import ShareList from './Lists/Share/ShareList';
import NavBar from './NavBar/NavBar';
import Tables from './Tables/Tables';
import { FileType, Filter } from './types';

type InfluencerManagementProps = {
	currentFolder: InfluencerListFolderModel | null;
	parentFolder: InfluencerListFolderModel | undefined;
	previousUrl: string;
	isLoading: boolean;
	mutate: MutateFn;
	folders: Array<InfluencerListFolderModel>;
	lists: Array<InfluencerListListModel>;
	itemsFromListFn: (list: InfluencerListListModel) => Array<InfluencerListListItemModel>;
	allUsers: Array<Model>;
};

const InfluencerManagement = ({
	currentFolder,
	parentFolder,
	previousUrl,
	isLoading,
	mutate,
	folders,
	lists,
	itemsFromListFn,
	allUsers,
}: InfluencerManagementProps) => {
	const [folderToEdit, setFolderToEdit] = useState<InfluencerListFolderModel | null>(null);
	const [listToEdit, setListToEdit] = useState<InfluencerListListModel | null>(null);
	const [folderToShare, setFolderToShare] = useState<InfluencerListFolderModel | null>(null);
	const [listToShare, setListToShare] = useState<InfluencerListListModel | null>(null);

	const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(false);
	const [displayList, setDisplaylist] = useState<boolean>(false);
	const [refetch, setRefetch] = useState<boolean>(false);

	const [selectedFilter, setSelectedFilter] = useState<Filter>(Filter.ALL);
	const [selectedTab, setSelectedTab] = useState<FileType>(FileType.ALL);

	const location = useLocation();
	const isBrandManager = usePermissions().isBrandManager();

	useEffect(() => {
		setSelectedFilter(Filter.ALL);
		if (location.pathname.includes('influencer-management/lists/')) {
			setDisplaylist(true);
		} else {
			setDisplaylist(false);
		}
	}, [location]);

	const updateListView = () => {
		// This can be removed when influencer list page has been refactored
		displayList && setRefetch(true);
	};

	return (
		<Styled.Wrapper>
			{displayList ? (
				<DiscoveryContextProvider>
					<InfluencerListContainer setRefetch={setRefetch} refetch={refetch} setListToShare={setListToShare} setSidebarIsOpen={setSidebarIsOpen} />
				</DiscoveryContextProvider>
			) : (
				<Styled.Content>
					<Styled.NavSection>
						<NavBar setSelectedFilter={setSelectedFilter} selectedFilter={selectedFilter} />
					</Styled.NavSection>
					<Styled.ListSection>
						<Styled.TopSection>
							<BreadCrumbs
								isLoading={isLoading}
								isStart={location.pathname === pathInfluencerManagement()}
								title={currentFolder?.attributes.name ?? 'All files'}
								homeUrl={previousUrl}
								homeText={parentFolder?.attributes.name ?? 'All files'}
							/>
							{!displayList && !isBrandManager && (
								<Styled.ButtonContainer>
									<CreateFolder
										mutate={mutate}
										setFolderToEdit={setFolderToEdit}
										isLoading={isLoading}
										setSelectedFilter={setSelectedFilter}
										setSelectedTab={setSelectedTab}
									/>
									<CreateList
										mutate={mutate}
										setListToEdit={setListToEdit}
										isLoading={isLoading}
										setSelectedFilter={setSelectedFilter}
										setSelectedTab={setSelectedTab}
									/>
								</Styled.ButtonContainer>
							)}
						</Styled.TopSection>
						<Tables
							selectedTab={selectedTab}
							setSelectedTab={setSelectedTab}
							selectedFilter={selectedFilter}
							setSelectedFilter={setSelectedFilter}
							mutate={mutate}
							isLoading={isLoading}
							folders={folders}
							setFolderToShare={setFolderToShare}
							setFolderToEdit={setFolderToEdit}
							folderToEdit={folderToEdit}
							lists={lists}
							setListToShare={setListToShare}
							setListToEdit={setListToEdit}
							listToEdit={listToEdit}
							setSidebarIsOpen={setSidebarIsOpen}
							itemsFromListFn={itemsFromListFn}
						/>
					</Styled.ListSection>
				</Styled.Content>
			)}
			{listToShare && (
				<ShareList
					updateListView={updateListView}
					allUsers={allUsers}
					sidebarIsOpen={sidebarIsOpen}
					setSidebarIsOpen={setSidebarIsOpen}
					listToShare={listToShare}
				/>
			)}
			{folderToShare && <ShareFolder allUsers={allUsers} sidebarIsOpen={sidebarIsOpen} setSidebarIsOpen={setSidebarIsOpen} folderToShare={folderToShare} />}
		</Styled.Wrapper>
	);
};

export default InfluencerManagement;
