import classNames from 'classnames';
import { useState, useEffect } from 'react';

import AffiliateMarketplace from 'components/AffiliateMarketplace';
import CampaignDetailSidebar from 'components/AffiliateMarketplace/Components/CampaignDetailSidebar';
import { allowedCountries } from 'components/AffiliateMarketplace/utils';
import PageNotFound from 'components/ErrorPages/PageNotFound';
import Icon from 'components/Icon';
import PageHeader from 'components/PageHeader/V2';
import { FEATURE_FLAG_AFFILIATE_MARKETPLACE } from 'constants/feature-flag-keys';
import { useFeatureToggle } from 'hooks/FeatureFlag/UseFeatureToggle';
import usePermissions from 'hooks/usePermissions';
import { useAppSelector, useAppDispatch } from 'hooks/useUserAppSelector';
import { setIsShowLeftSidebar } from 'reducers/AffiliateMarketplaceReducers/AMInfluencerSlice';

import Styled from './AffiliateMarketplace.style';

const AffiliateMarketplaceView = () => {
	const dispatch = useAppDispatch();
	const [isEnabled] = useFeatureToggle();
	const { isInfluencer } = usePermissions();
	const user = useAppSelector((state) => state.user);
	const { isShowDetailSidebar, isShowLeftSidebar, isLeftSidebarHover } = useAppSelector((state) => state.amInfluencer);
	const [isIconMouseHover, setIsIconMouseHover] = useState<boolean>(false);
	const [isMakeIconDarker, setIsMakeIconDarker] = useState<boolean>(isLeftSidebarHover);
	useEffect(() => {
		if (isShowDetailSidebar) {
			const layoutDiv = document.querySelector('.layout');
			if (layoutDiv) {
				(layoutDiv as HTMLElement).style.overflowY = 'hidden';
			}
		} else {
			const layoutDiv = document.querySelector('.layout');
			if (layoutDiv) {
				(layoutDiv as HTMLElement).style.overflowY = 'auto';
			}
		}
	}, [isShowDetailSidebar]);

	useEffect(() => {
		setIsMakeIconDarker(isLeftSidebarHover);
	}, [isLeftSidebarHover]);

	const canAccessMarketplace = isEnabled(FEATURE_FLAG_AFFILIATE_MARKETPLACE);

	const selectedUserCountry = user.countryCode || '';
	const canUseAMByCountry = allowedCountries.includes(selectedUserCountry);

	return (
		<>
			{canAccessMarketplace ? (
				<Styled.Wrapper className={classNames({ 'show-sidebar': isShowLeftSidebar })}>
					<PageHeader
						cssClass='influencer-affiliate-header'
						heading={
							<Styled.HeaderWrapper>
								<h1 data-testid='page-title'>Affiliate Marketplace</h1>
								<Styled.IconWrapper
									className={classNames('sidebar-icon-wrapper', { 'fill-darker': isMakeIconDarker, disabled: !canUseAMByCountry })}
									onMouseEnter={() => {
										canUseAMByCountry && setIsIconMouseHover(true);
									}}
									onMouseLeave={() => {
										canUseAMByCountry && setIsIconMouseHover(false);
									}}
									onClick={() => {
										canUseAMByCountry && dispatch(setIsShowLeftSidebar(!isShowLeftSidebar));
									}}
								>
									<Icon name={isShowLeftSidebar ? 'chevron-left-double' : isIconMouseHover ? 'chevron-right-double' : 'sidebar-right-arrow'} size='20' />
								</Styled.IconWrapper>
							</Styled.HeaderWrapper>
						}
					/>
					<AffiliateMarketplace isNotInfluencer={!isInfluencer()} />
					<CampaignDetailSidebar isNotInfluencer={!isInfluencer()} />
				</Styled.Wrapper>
			) : (
				<PageNotFound />
			)}
		</>
	);
};

export default AffiliateMarketplaceView;
