import { Player } from '@lottiefiles/react-lottie-player';

import Heart from 'assets/animations/500-error.json'; // eslint-disable-line import/extensions
import { Heading } from 'components/Heading';
import Icon from 'components/Icon';
import Grid from 'styles/grid/grid';

import Styled from './PageNotFound.style';

/**
 */

const SomethingWentWrong = () => {
	return (
		<Styled.Container>
			<Styled.Wrapper>
				<Styled.GridContainer>
					<Grid.Column xs={1} lg={2} />
					<Grid.Column xs={10} lg={4}>
						<Styled.HideOnLargeDevice>
							<Player src={Heart} autoplay={true} loop style={{ height: '350px', width: '350px' }} />
						</Styled.HideOnLargeDevice>
						<Styled.Title>
							<Heading as='h2'>500</Heading>
							<Heading as='h5'>This is not fine...</Heading>
						</Styled.Title>
						<Styled.Description>...but something went wrong, and we are working as fast as we can to fix the problem for you.</Styled.Description>
						<Styled.Description>In the meantime, here is what you can do:</Styled.Description>
						<Styled.List>
							<li>
								<Icon name='refresh' />
								<span>
									<strong>Refresh the page</strong> (sometimes that helps).
								</span>
							</li>
							<li>
								<Icon name='pending' />
								<span>
									<strong>Try again</strong> in 30 minutes.
								</span>
							</li>
							<li>
								<Icon name='mail' />
								<span>
									<strong>Email us</strong> at <a href='mailto:support@collabs.se'>support@collabs.se</a> and tell us.
								</span>
							</li>
						</Styled.List>
					</Grid.Column>
					<Grid.Column xs={1} lg={1} />
					<Grid.Column md={0} lg={2}>
						<Styled.HideOnSmallDevice>
							<Player src={Heart} autoplay={true} loop style={{ height: '500px', width: '500px' }} />
						</Styled.HideOnSmallDevice>
					</Grid.Column>
				</Styled.GridContainer>
			</Styled.Wrapper>
			<Styled.Footer>
				<p>© 2024 Collabs AB</p>
				<div>
					<a href='https://help.collabs.se/hc/en-us' target='_blank' rel='noreferrer'>
						Go to Help center
					</a>
					<a href='https://www.collabs.se/' target='_blank' rel='noreferrer'>
						Collabs.se
					</a>
				</div>
			</Styled.Footer>
		</Styled.Container>
	);
};

export default SomethingWentWrong;
