import { Player } from '@lottiefiles/react-lottie-player';
import { Link } from 'react-router-dom';

import Heart from 'assets/animations/Error404.json'; // eslint-disable-line import/extensions
import Grid from 'styles/grid/grid';

import Styled from './PageNotFound.style';

/**
 */

type Props = {
	previousUrl?: string;
};

const PageNotFound = ({ previousUrl }: Props) => (
	<Styled.Container data-testid='404-page'>
		<Styled.Wrapper>
			<Styled.GridContainer>
				<Grid.Column xs={1} lg={2} />
				<Grid.Column xs={10} lg={4}>
					<Styled.HideOnLargeDevice>
						<Player src={Heart} autoplay={true} loop style={{ height: '350px', width: '350px' }} />
					</Styled.HideOnLargeDevice>
					<Styled.Title>
						<h2>404</h2>
						<h5>No likes for this page.</h5>
					</Styled.Title>
					<Styled.Description>
						Sorry, but we could not find the page you were looking for. Maybe going back <Link to={previousUrl ?? '/'}>Home</Link> helps?
					</Styled.Description>
				</Grid.Column>
				<Grid.Column xs={1} lg={1} />
				<Grid.Column md={0} lg={2}>
					<Styled.HideOnSmallDevice>
						<Player src={Heart} autoplay={true} loop style={{ height: '350px', width: '350px' }} />
					</Styled.HideOnSmallDevice>
				</Grid.Column>
			</Styled.GridContainer>
		</Styled.Wrapper>
		<Styled.Footer>
			<p>© 2024 Collabs AB</p>
			<div>
				<a href='https://help.collabs.se/hc/en-us' target='_blank' rel='noreferrer'>
					Go to Help center
				</a>
				<a href='https://www.collabs.se/' target='_blank' rel='noreferrer'>
					Collabs.se
				</a>
			</div>
		</Styled.Footer>
	</Styled.Container>
);

export default PageNotFound;
