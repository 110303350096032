import { CSSProperties } from 'react';
import styled from 'styled-components';

import { Button } from 'components/Button';
import Icon from 'components/Icon';
import Grid from 'styles/grid/grid';
import Row from 'styles/grid/row';
import newColors from 'styles/theme/colors';
import borderRadius from 'styles/variables/border-radius';
import colors from 'styles/variables/colors';
import fontSize from 'styles/variables/font-size';
import { guttersWithRem } from 'styles/variables/gutter';
import { breakpoints, devices } from 'styles/variables/media-queries';
import typography from 'styles/variables/typography';

const Wrapper = styled(Row)`
	width: 100%;
`;

const Section = styled(Grid.Column)<{ height?: number; maxHeight?: number }>`
	height: ${(props) => props.height && `${props.height}px`};
	max-height: ${(props) => props.maxHeight && `${props.maxHeight}px`};
	border: 1px solid ${colors.borderGray};
`;

const SearchInputSection = styled.div`
	position: relative;
	margin-bottom: 20px;
	width: 100%;
`;

const Header = styled.div`
	width: 100%;
`;

const HeaderInnerWrapper = styled.div`
	max-width: 100%;
	margin-top: ${guttersWithRem.m};

	@media (min-width: ${breakpoints.lg}) {
		max-width: 1280px;
		width: calc(100vw - 21rem);

		margin: 0;
	}

	& > div {
		padding: 0;

		@media (min-width: ${breakpoints.lg}) {
			padding-left: 100px;
			padding-right: 200px;
		}
	}
`;

const AutoCorrection = styled.div`
	font-family: ${typography.BaseFontFamiliy};
	font-size: ${fontSize.s};
	margin-bottom: ${guttersWithRem.s};
`;

const FlexDiv = styled.div<CSSProperties>`
	display: flex;
	flex: ${(props) => props.flex};
	padding: ${(props) => props.padding};
	height: ${(props) => props.height};
	width: ${(props) => props.width};
	position: ${(props) => props.position};
	column-gap: ${(props) => props.columnGap};
	justify-content: ${(props) => props.justifyContent};
	align-items: ${(props) => props.alignItems};
	top: ${(props) => props.top};
	left: ${(props) => props.left};
	right: ${(props) => props.right};
	bottom: ${(props) => props.bottom};
	overflow-x: ${(props) => props.overflowX};
`;

const ButtonsWrapper = styled(FlexDiv)`
	flex-direction: column;
	width: 100%;
	margin-bottom: ${guttersWithRem.m};
	row-gap: ${guttersWithRem.m};

	@media (min-width: ${breakpoints.md}) {
		flex-direction: row;
		align-items: center;
	}
`;

const CustomIcon = styled(Icon)``;

const CustomButton = styled(Button)`
	background-color: ${newColors.mist};
	white-space: nowrap;
	max-height: 44px;
	border: 1px solid ${newColors.mist};

	${CustomIcon} {
		&.icon {
			margin-left: 0;
			line-height: 0;
		}
	}

	&.filters {
		padding-top: ${guttersWithRem.xxs};
		padding-bottom: ${guttersWithRem.xxs};
	}

	&:hover {
		path {
			fill: ${colors.white};
		}
	}

	&.filter-opened {
		background-color: ${colors.discovery.black};
		color: ${colors.white};
		path {
			fill: ${colors.white};
		}
	}
`;

const FilterSummaryWrapper = styled.div`
	display: flex;
	align-items: flex-end;
	column-gap: ${guttersWithRem.xxs};
`;

const Filters = styled.div`
	display: flex;
	max-width: 600px;
	flex-wrap: wrap;
	gap: ${guttersWithRem.xxs};
`;

const ResultContentWrapper = styled.div`
	display: flex;
	overflow: hidden;
	min-height: 300px;
`;

const SearchTotalResultCount = styled.span<{ executionTimeMs: number | null }>`
	position: relative;
	display: inline-block;

	& > span {
		&:hover:before {
			content: '(${(props) => props.executionTimeMs && props.executionTimeMs / 1000} seconds)		';
			white-space: nowrap;
			font-size: 0.625rem;
			z-index: 10;
			position: absolute;
			bottom: -10px;
		}
	}
`;

const ClearFilterButton = styled.div`
	cursor: pointer;
	color: ${colors.black};
	border-bottom: 1px solid ${colors.black};
	white-space: nowrap;
`;

const FilterButtonAndTag = styled.div`
	align-items: baseline;
	column-gap: ${guttersWithRem.xs};
	width: fit-content;
	justify-content: space-between;
	margin-bottom: 24px;
	display: none;
	@media screen and (${devices.md}) {
		display: flex;
	}
`;

const SelectedInfluencer = styled.div`
	font-size: 0.875rem;
	font-weight: 600;
	.icon {
		margin-top: 3px;
	}
`;

const SelectedInfluencerContainer = styled.div`
	cursor: pointer;
	display: flex;
	gap: 8px;
	padding: 0.5rem 1rem;
	align-items: center;
	white-space: nowrap;
	height: 40px;
	background-color: ${colors.buttonGray};
	border-radius: ${borderRadius.m};
	&:hover {
		background-color: ${colors.gray9};
	}
`;

const ClearFilterButtonContainer = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: end;
	gap: 8px;
	height: 40px;
`;

const SearchInputWrapper = styled.div`
	margin-bottom: ${guttersWithRem.xxs};
	display: flex;
	align-items: center;
	gap: 8px;
`;

const ResultText = styled.p`
	margin-bottom: 16px;
	strong {
		font-weight: ${({ theme }) => theme.fontWeights.bold};
	}
`;

const ButtonsWrapperDrawer = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	gap: 4px;
	border-top: 1px solid ${newColors.ash};
	padding-top: 2rem;
	button {
		width: 100%;
	}
`;

const SearchArea = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	gap: 16px;
	flex-direction: column;
	@media screen and (${devices.md}) {
		flex-direction: row;
	}
`;

const TabsWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	@media screen and (${devices.md}) {
		width: auto;
		margin-bottom: 24px;
	}
`;

const IconContainer = styled.div`
	display: flex;
	@media screen and (${devices.md}) {
		display: none;
	}
`;

const Divider = styled.div`
	height: 24px;
	width: 2px;
	border-right: 1px solid ${newColors.ash};
	margin-right: 16px;
`;

const Styled = {
	Text,
	Divider,
	IconContainer,
	TabsWrapper,
	SearchArea,
	ButtonsWrapperDrawer,
	SearchInputWrapper,
	ClearFilterButtonContainer,
	SelectedInfluencerContainer,
	Wrapper,
	Section,
	Header,
	HeaderInnerWrapper,
	SearchInputSection,
	AutoCorrection,
	ButtonsWrapper,
	CustomIcon,
	FlexDiv,
	CustomButton,
	ResultContentWrapper,
	SearchTotalResultCount,
	ClearFilterButton,
	FilterSummaryWrapper,
	Filters,
	FilterButtonAndTag,
	SelectedInfluencer,
	ResultText,
};

export default Styled;
