import classNames from 'classnames';
import { orderBy, isNil } from 'lodash';
import { useEffect } from 'react';

import useAffiliateMarketplaceData from 'components/AffiliateMarketplaceCampaignForm/hooks';
import { useAppDispatch, useAppSelector } from 'hooks/useUserAppSelector';
import { setDashboardCampaignCards, resetAMSlice, setSearchText, setIsLeftSidebarHover } from 'reducers/AffiliateMarketplaceReducers/AMInfluencerSlice';

import Styled from './AffiliateMarketplace.style';
import ActiveCampaigns from './Components/ActiveCampaigns';
import Sidebar from './Components/Sidebar';

type Props = {
	isNotInfluencer?: boolean;
};

/**
 * AffiliateMarketplace
 * @returns {JSX.Element}
 */
const AffiliateMarketplace = ({ isNotInfluencer }: Props): JSX.Element => {
	const { isShowLeftSidebar } = useAppSelector((state) => state.amInfluencer);

	const dispatch = useAppDispatch();
	const { getPrograms } = useAffiliateMarketplaceData();
	const { models, isLoading, error } = getPrograms();

	const onSearch = (value: string) => {
		dispatch(setSearchText(value));
	};

	useEffect(() => {
		if (!isLoading) {
			let activeCampaignCards = [];
			if (!isNil(models)) {
				activeCampaignCards = models.findAll('affiliateProgram');
			}
			const newActiveCampaignCards = orderBy(activeCampaignCards, 'sort', 'asc');
			dispatch(setDashboardCampaignCards(newActiveCampaignCards));
		}
	}, [isLoading]);

	useEffect(() => {
		return () => {
			dispatch(resetAMSlice());
		};
	}, []);

	return (
		<Styled.AffiliateMarketplace data-testid='affiliate-marketplace' className={classNames({ 'side-open': isShowLeftSidebar })}>
			<Styled.LeftContainer
				onMouseEnter={() => {
					dispatch(setIsLeftSidebarHover(true));
				}}
				onMouseLeave={() => {
					dispatch(setIsLeftSidebarHover(false));
				}}
			>
				<Sidebar onSearch={onSearch} />
			</Styled.LeftContainer>
			<Styled.MainContainer>
				<Styled.MainContainerInner>
					<ActiveCampaigns isLoading={isLoading} isError={!isNil(error)} isSidebarOpen={isShowLeftSidebar} isNotInfluencer={isNotInfluencer} />
				</Styled.MainContainerInner>
			</Styled.MainContainer>
		</Styled.AffiliateMarketplace>
	);
};

export default AffiliateMarketplace;
