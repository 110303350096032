import { Player } from '@lottiefiles/react-lottie-player';
import { Link, useNavigate } from 'react-router-dom';

import Heart from 'assets/animations/Error403.json'; // eslint-disable-line import/extensions
import Grid from 'styles/grid/grid';

import Styled from './PageNotFound.style';

/**
 */

const ForbiddenPage = () => {
	const navigate = useNavigate();

	return (
		<Styled.Container>
			<Styled.Wrapper>
				<Styled.GridContainer>
					<Grid.Column xs={1} lg={2} />
					<Grid.Column xs={10} lg={4}>
						<Styled.HideOnLargeDevice>
							<Player src={Heart} autoplay={true} style={{ height: '350px', width: '350px' }} />
						</Styled.HideOnLargeDevice>
						<Styled.Title>
							<h2>403</h2>
							<h5>Access denied.</h5>
						</Styled.Title>
						<Styled.Description>
							Sorry, you are not allowed to go in here.{' '}
							<a href='#' onClick={() => navigate(-1)}>
								Go back
							</a>{' '}
							or go <Link to='/'>home</Link>.
						</Styled.Description>
					</Grid.Column>
					<Grid.Column xs={1} lg={1} />
					<Grid.Column md={0} lg={2}>
						<Styled.HideOnSmallDevice>
							<Player src={Heart} autoplay={true} style={{ height: '350px', width: '350px' }} />
						</Styled.HideOnSmallDevice>
					</Grid.Column>
				</Styled.GridContainer>
			</Styled.Wrapper>
			<Styled.Footer>
				<p>© 2024 Collabs AB</p>
				<div>
					<a href='https://help.collabs.se/hc/en-us' target='_blank' rel='noreferrer'>
						Go to Help center
					</a>
					<a href='https://www.collabs.se/' target='_blank' rel='noreferrer'>
						Collabs.se
					</a>
				</div>
			</Styled.Footer>
		</Styled.Container>
	);
};

export default ForbiddenPage;
