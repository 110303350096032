import styled from 'styled-components';

import { GhostButton } from 'components/Button';
import colors from 'styles/variables/colors';
import { devices } from 'styles/variables/media-queries';
import { px, spacing } from 'styles/variables/spacings';

const ActiveCampaigns = styled.div``;

const HeadlineSection = styled.h2`
	display: flex;
	justify-content: space-between;
	align-items: center;

	height: 44px;
	font-size: 1.2rem;
	font-style: normal;
	font-weight: 500;

	.counter {
		margin-left: ${spacing[0].px};
		color: ${colors.AffiliateList.counterColor};
	}

	margin-left: ${px[3]}px;
	margin-right: ${px[3]}px;
	margin-bottom: ${px[3]}px;

	@media screen and (${devices.lg}) {
		margin-left: 0;
		margin-right: 0;
		margin-bottom: ${px[3]}px;
	}
`;

const Items = styled.ol<{ isSidebarOpen: boolean }>`
	display: grid;
	gap: 16px;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	list-style: none;
	grid-auto-rows: 1fr;

	margin-left: ${px[3]}px;
	margin-right: ${px[3]}px;
	margin-bottom: 0;

	@media screen and (${devices.md}) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	@media screen and (${devices.xl}) {
		margin-left: 0;
		margin-right: 0;
		grid-template-columns: ${(props) => (props.isSidebarOpen ? `repeat(3, minmax(0, 1fr))` : `repeat(4, minmax(0, 1fr))`)};
	}
`;

const Item = styled.li`
	grid-column: span 1;

	.affiliate-card {
		height: 100%;
	}
`;

const TextContainer = styled.div`
	margin-left: ${px[3]}px;
	margin-right: ${px[3]}px;

	p {
		margin: 0;
	}

	@media screen and (${devices.xl}) {
		margin-left: 0;
		margin-right: 0;
	}
`;

const TabButtonsWrapper = styled.div`
	display: flex;
	column-gap: ${spacing[2].px};

	margin-left: ${px[3]}px;
	margin-right: ${px[3]}px;
	margin-bottom: ${spacing[3].px};

	@media screen and (${devices.lg}) {
		margin-left: 0;
		margin-right: 0;
		margin-bottom: ${spacing[3].px};
	}
`;

const Tab = styled.button`
	border-radius: ${({ theme }) => theme.radius.default};
	border: 1px solid ${colors.AffiliateList.tab.border};
	padding: ${spacing[2].rem};
	cursor: pointer;

	font-weight: ${({ theme }) => theme.fontWeights.medium};
	background-color: transparent;

	&.selected {
		background-color: ${colors.AffiliateList.tab.selectedBackground};
		border-color: ${colors.AffiliateList.tab.selectedBackground};
		color: ${colors.AffiliateList.tab.selectedColor};
	}
`;

const Button = styled(GhostButton)``;

const EmptyMessageContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
`;

const Styled = {
	ActiveCampaigns,
	HeadlineSection,
	Items,
	Item,
	TextContainer,
	TabButtonsWrapper,
	Tab,
	Button,
	EmptyMessageContainer,
};

export default Styled;
