import { combineReducers, configureStore, Middleware } from '@reduxjs/toolkit';

import { KEY_COLLABS_API_USER_OBJECT } from 'constants/localStorage-keys';
import AMInfluencerSlice from 'reducers/AffiliateMarketplaceReducers/AMInfluencerSlice';
import ContentManagementSlice from 'reducers/ContentManagementReducers/ContentManagementSlice';
import mercureMiddleware from 'reducers/MercureReducer/mercureMiddleware';
import SegmentSlice from 'reducers/SegmentReducers/SegmentSlice';
import { userNotificationsReducer, userPermissionsReducer, userReducer } from 'reducers/UserReducers';
import BrowserStorage from 'shared/helpers/BrowserStorage/BrowserStorage';
import SidebarSlice from 'views/influencer/Payment/reducers/SidebarSlice';

import { loadState } from './Utils';

const rootReducers = combineReducers({
	user: userReducer,
	userPermissions: userPermissionsReducer,
	userNotifications: userNotificationsReducer,
	payment: SidebarSlice,
	amInfluencer: AMInfluencerSlice,
	contentManagement: ContentManagementSlice,
	segments: SegmentSlice,
});

export type RootState = ReturnType<typeof rootReducers>;
export type AppDispatch = typeof store.dispatch;

const storeUserStateMiddleware: Middleware<{}, RootState> = (store) => (next) => (action) => {
	const result = next(action);
	const userState = store.getState().user;
	new BrowserStorage().setItem(KEY_COLLABS_API_USER_OBJECT, JSON.stringify(userState));
	return result;
};

export const store = configureStore({
	reducer: rootReducers,
	devTools: process.env.NODE_ENV !== 'production',
	preloadedState: {
		user: loadState(KEY_COLLABS_API_USER_OBJECT),
	},
	// @ts-ignore
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(mercureMiddleware, storeUserStateMiddleware),
});
