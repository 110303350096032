import Icon from 'components/Icon';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { breakpoints } from 'styles/variables/media-queries';

import Styled from './BackButton.style';

const BackButton = (props: { onClick: () => void }) => {
	const { width } = useWindowDimensions();
	const isMobileView = width <= +breakpoints.lg.split('px')[0];
	return (
		<Styled.Back onClick={props.onClick}>
			<Icon name='arrow-left' size='16' />
			<span>{isMobileView ? 'Back' : 'Close'}</span>
		</Styled.Back>
	);
};

export default BackButton;
