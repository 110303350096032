import DOMPurify from 'dompurify';

import TrackingLink from 'components/ContentManagement/Components/TrackingLink';
import usePermissions from 'hooks/usePermissions';
import { ASSIGNMENT_TYPE } from 'types/AssignmentTypes';

import Styled from './ApproveMessage.style';

type ApproveMessageProps = {
	contentType: ASSIGNMENT_TYPE;
	trackingLink?: string;
	campaignCode?: string;
};

/**
 * ApproveMessage
 * @returns {JSX.Element}
 */
const ApproveMessage = ({ contentType = ASSIGNMENT_TYPE.INSTAGRAM_POST, trackingLink, campaignCode }: ApproveMessageProps): JSX.Element => {
	const { isInfluencer } = usePermissions();

	const getTypeText = () => {
		switch (contentType) {
			case ASSIGNMENT_TYPE.INSTAGRAM_STORY:
				return 'story';
			case ASSIGNMENT_TYPE.INSTAGRAM_REEL:
				return 'reel';
			case ASSIGNMENT_TYPE.TIKTOK_VIDEO:
				return 'tiktok';
			default:
				return 'post';
		}
	};

	const getRememberText = () => {
		if (contentType === ASSIGNMENT_TYPE.INSTAGRAM_STORY) {
			return `Remember to <strong>select your frames or enter your statistics manually</strong> as soon as possible.`;
		}

		return `Remember to <strong>add the link to your ${getTypeText()}</strong> as soon as possible.`;
	};

	return (
		<Styled.Wrapper>
			{isInfluencer() && (
				<Styled.Content>
					<h5>Your content has been approved for publication</h5>
					<p>You are now ready to publish your content on the specified date or date range.</p>
					<ol className='help-text'>
						<li>Please ensure that you use the correct captions and specified links, including tracking links.</li>
						<li>
							After publishing, please add the post statistics under the stats tab.{' '}
							<span data-testid='type-text' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getRememberText()) }}></span>
						</li>
					</ol>
					<p className='mb-0'>Good luck!</p>
				</Styled.Content>
			)}
			{trackingLink && (
				<Styled.TrackingLinkContainer>
					<TrackingLink isLoading={false} originalUrl={trackingLink} trackingUrl={trackingLink} canEdit={false} assignmentType={contentType} hideField />
				</Styled.TrackingLinkContainer>
			)}
			{campaignCode && (
				<Styled.TrackingLinkContainer>
					<TrackingLink isLoading={false} originalUrl={campaignCode} trackingUrl='' canEdit={false} assignmentType={contentType} hideField />
				</Styled.TrackingLinkContainer>
			)}
		</Styled.Wrapper>
	);
};

export default ApproveMessage;
